import * as React from "react";

import { clsx } from "clsx";
import { Combobox } from "@headlessui/react";
import Bounce from "react-spinners/BounceLoader";
import { Building, MapPin, Search, XIcon } from "lucide-react";
import { Button } from "./button";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  getPlacePredictions: (options: { input: string }) => void;
  loading: boolean;
  handleChange: (
    placeDetails: google.maps.places.PlaceResult | undefined
  ) => void;
  placePredictions: google.maps.places.AutocompletePrediction[];
  placesService: google.maps.places.PlacesService | null;
  home?: boolean;
};

const GoogleAutoComplete = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      getPlacePredictions,
      handleChange,
      home = false,
      loading,
      placePredictions,
      placesService,
      value,
      ...props
    },
    ref
  ) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedPrediction, setSelectedPrediction] =
      React.useState<google.maps.places.AutocompletePrediction>();

    React.useEffect(() => {
      if (selectedPrediction && isDirty) {
        placesService?.getDetails(
          {
            placeId: selectedPrediction.place_id,
          },
          (placeDetails) => handleChange(placeDetails || undefined)
        );
      }
    }, [handleChange, isDirty, placesService, selectedPrediction]);

    const clearInput = () => {
      setIsDirty(false);
      setSelectedPrediction(undefined);
      handleChange(undefined);
    };

    return (
      <Combobox
        as="div"
        value={value}
        // onChange={(newValue) => {
        //   console.log(
        //     "🚀 ~ Combobox oncChange fired with new value:",
        //     newValue
        //   );
        // }}
        className={`w-full`}
      >
        <span
          data-slot="control"
          className={
            home
              ? "relative block h-12 w-full"
              : clsx([
                  className,

                  // Basic layout
                  "relative block w-full",

                  // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
                  "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius)-1px)] before:bg-white before:shadow",

                  // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                  "dark:before:hidden",

                  // Focus ring
                  "after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent sm:after:focus-within:ring-1 sm:after:focus-within:ring-blue",

                  // Disabled state
                  "has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none",

                  // Invalid state
                  "before:has-[[data-invalid]]:shadow-red-500/10",
                ])
          }
        >
          <Combobox.Input
            className={
              home
                ? "w-full h-full rounded-full border-2 border-gray-300 pl-4 pr-12 focus-visible:ring-orange-500"
                : clsx([
                    // Basic layout
                    "relative block h-[45px] w-full overflow-hidden appearance-none rounded px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]",

                    // Typography
                    "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-base/6 dark:text-white",

                    // Border
                    "border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20",

                    // Background color
                    "bg-transparent dark:bg-white/5",

                    // Hide default focus styles
                    "focus:outline-none",

                    // Invalid state
                    "data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500",

                    // Disabled state
                    "data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]",
                  ])
            }
            onChange={(evt) => {
              if (evt.target.value === "") {
                clearInput();
              } else {
                setIsDirty(true);
                getPlacePredictions({
                  input: evt.target.value,
                });
              }
            }}
            onBlur={() => {
              if ((placePredictions?.length || 0) > 0 && isDirty)
                setSelectedPrediction(placePredictions?.[0]);
            }}
            autoComplete="off"
            ref={ref}
            {...props}
          />
          {loading && (
            <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center pr-3">
              {/* Replace this with your actual loading indicator */}
              <Bounce color="orange" size={18} />
            </div>
          )}
          {value && (
            <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center">
              {/* Replace this with your actual loading indicator */}
              <Button variant="ghost" onClick={() => clearInput()}>
                <XIcon className="w-4 h-4" />
              </Button>
            </div>
          )}
          {home && (
            <Button
              type="submit"
              size="icon"
              className="absolute -translate-y-1/2 bg-orange-500 rounded-full right-1 top-1/2 hover:bg-orange-600"
            >
              <Search className="w-4 h-4" />
              <span className="sr-only">Search</span>
            </Button>
          )}
        </span>
        <Combobox.Options className="absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {placePredictions?.map((place) => {
            const start = place.matched_substrings[0]?.offset || 0;
            const end =
              start +
              (place.matched_substrings[0]
                ? place.matched_substrings[0].length
                : 0);

            const beforeBold = place.description.substring(0, start);
            const bold = place.description.substring(start, end);
            const afterBold = place.description.substring(end);

            const type = place.types?.[0];
            return (
              <Combobox.Option
                key={place.place_id}
                value={place}
                onClick={() => {
                  setIsDirty(false);
                  setSelectedPrediction(place);
                }}
                className={clsx([
                  // Basic layout
                  "relative flex flex-row items-center w-full appearance-none px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]",

                  // Typography
                  "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",

                  // Background color
                  "bg-transparent dark:bg-white/5 hover:bg-orange-50",

                  // Hide default focus styles
                  "focus:outline-none",

                  // Invalid state
                  "data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500",

                  // Disabled state
                  "data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]",
                ])}
              >
                {type === "premise" ||
                type === "street_address" ||
                type === "geocode" ? (
                  <Building className="w-5 h-5 mr-1 text-slate-600" />
                ) : (
                  <MapPin className="w-5 h-5 mr-1 text-slate-600" />
                )}
                <span className="align-bottom">
                  {beforeBold}
                  <span className="font-bold">{bold}</span>
                  {afterBold}
                </span>
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Combobox>
    );
  }
);
GoogleAutoComplete.displayName = "GoogleAutoComplete";

export { GoogleAutoComplete };

// placeComponents=NobwRANg9gdg5gfRgQwLYFMwC4wEEDOAFugG4CWEEmANGEVAE4AuSamOBx5lNYTAngAd0+bMEhQAxsghkBYWoKiymZaRDABdAL7Vw0eKwzYwAIQCuMSVFQAjdAAIAwlEvza9ZkfZnL1u44ubvwKfEIiYmDIACaoZDBk+EwMyKok6AjIDOjICFTpEAgATKFKKmoyWrr6sIgoxjgAcoxMhM5ZyvHIoZ4s9T6NTqECwqJY4jFxCUkpaRlZOXmk6IUAjKWdqupVehKG/SYAqglM6NEOAMpMqREehC3eRxfD4WPi1m4MIYqbFRo6miAA=
